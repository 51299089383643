//チェックの有無によって確認ボタンの活性・非活性

	var btn = $('.p-registerInput__confirmBtn')

	$(btn).addClass('-disabled');

	$('#js-agree').on('click', function() {
		if ( $(this).prop('checked') == false ) {
			$(btn).addClass('-disabled');
		} else {
			$(btn).removeClass('-disabled');
		}
	});

