  const rellax = new Rellax(".rellax-target", {
    // speed: -2,
    // center: true,
    // wrapper: null,
    // wrapper: '.l-container',
    // round: true,
    // vertical: true,
    // horizontal: false,
    breakpoints:[375,768,1200]
  });
