// グロナビのmessageメニューをクリックしたらheader内要素の色を白にする
$(function () {
  $('.menu-message').on('click', function () {
    $(".js-header").removeClass("color-default");
  });
});

//タイトルロゴの表示・非表示(TOPページはスクロールしてページトップから60pxに達したらボタンを表示。それ以外のページはデフォで表示しておく)
$(function() {
  const title = $('.l-header__heading01');
  const path = location.pathname
  if (path == "/"){
  $(window).on('scroll', function(){
    if($(this).scrollTop() > 400) {
      title.addClass('scroll-active');
    }else{
      title.removeClass('scroll-active');
    }
  });
  }
  else {
  $(function(){
    title.addClass('scroll-active');
  });
}
});


// グロナビのメニューをクリックした時の挙動
$(function () {
  $('.l-header__itemEn').on('click', function () {
    $('.l-header__navigation').toggleClass('is-open');
    $('.l-header__hamBtn').toggleClass('active');
    $('.body').removeClass('is-fixed');
    $('.l-header__heading01').removeClass('nav-active'); 
    // $(".js-header").removeClass("color-default");
  });
});

// ハンバーガーメニューbtnを押した時のnavの表示・非表示
$(function () {
  $('.l-header__hamBtn').on('click', function () {
    $(this).toggleClass('active'); //hamBtnの動き
    $('.l-header__navigation').toggleClass('is-open'); //ナビゲーションの表示・非表示
    $('.body').toggleClass('is-fixed'); //ナビゲーション開いている間はbodyスクロールできない。safari問題？要確認！
    $('.l-header__heading01').toggleClass('nav-active'); //header内h1の表示・非表示
    $('.c-btn__top').toggleClass('is-hide'); //topへ戻るボタン非表示にする
    $('.l-header__item').toggleClass("slideUp");
  });
});

//TOPへ戻るボタンの挙動
$('#to-top').on('click', function () {
  $('html,body').animate({scrollTop: 0}, 300)
});

// スクロールするとheader内要素の色変更
$(function () {
  $(window).on("scroll", function () {
    const sliderHeight = $(".l-kv__pc").height();
    if (sliderHeight - 30 < $(this).scrollTop()) {
      $(".js-header").addClass("color-change");
    } else {
      $(".js-header").removeClass("color-change");
    }
  });
});

// navが開いている間はheader内要素をデフォルトカラーにする
$(function () {
  $(".l-header__hamBtn").on('click', function () {   // トリガーをクリックした時の条件分岐
    if ($(this).hasClass("active")) {  // ナビが開いた時の処理
      $(".js-header").addClass("color-default");  // デフォルトカラーにする
    } else {                              // ナビが閉じた時の処理
      $(".js-header").removeClass("color-default");  // 指定のカラーに戻す
    }
  });
});


//TOPページのmessageセクション間はロゴの色を変更
$(function(){ 
  const path = location.pathname
  if (path == "/"){
$(function(){	
  $(window).on("scroll", function(){
//セクション1から2の間はこれ
if ($(window).scrollTop() > $('#js-message').offset().top && $(window).scrollTop() < $('#js-topService').offset().top){
  $(".js-header").addClass("color-change");
  $(".l-header__button").addClass("color-hanten");
}//セクション2より進んだらこれ
else if($(window).scrollTop() > $('#js-message').offset().top){
  $(".js-header").removeClass("color-change");
  $(".l-header__button").removeClass("color-hanten");
   } 
else {
  $(".l-header__button").removeClass("color-hanten");
  }
  });
});
  }
});

//messageページの"成功の可能性を高める準備をしよう"エリアはロゴの色を変更
$(function(){ 
  const path = location.pathname
  if (path == "/message.html"){
$(function(){	
  $(window).on("scroll", function(){
//成功の可能性を〜セクションからfooter上まではこれ
if ($(window).scrollTop() > $('#js-messageMore').offset().top && $(window).scrollTop() < $('#js-footer').offset().top){
  $(".js-header").addClass("color-change");
  $(".l-header__button").addClass("color-hanten");
}
//それ以外はデフォに戻す
else {
  $(".l-header__button").removeClass("color-hanten");
  }
  });
});
  }
});
//TOPページのmessageセクション間はcolor-defaultクラスを除去する
$(function(){ 
  const path = location.pathname
  if (path == "/"){
$(function(){	
  $(window).on("scroll", function(){
//セクション1から2の間はこれ
($(window).scrollTop() > $('#js-message').offset().top && $(window).scrollTop() < $('#js-topService').offset().top)
  $(".js-header").removeClass("color-default");
  });
});
  }
});

//kvの高さ分だけメインコンテンツを下げる。
$(function() {
  const height = $(".js-kv").height();
  $(".l-container").css("padding-top", height);
});

//最初非表示にしていたコンテンツを表示させる
$(function() {
  const hideContainer = $('.l-container');
  //ページが読み込まれたら、is-showクラスを付与してmessageセクションを表示
  // $(window).on('load', function(){
    hideContainer.addClass("is-show");
// });
});

//トップページ スクロールしたら各セクションの英字見出しが出現する
$(function() {
  $(window).on("scroll", function() {
    $(".c-topHeading02").each(function() {
      const scroll = $(window).scrollTop();
      const blockPosition = $(this).offset().top;
      const windowHeihgt = $(window).height();
      if (scroll > blockPosition - windowHeihgt + 200) {
        $(this).addClass("slideUp");
      }
    });
  });
});


//下層ページ loadしたら各セクションの英字見出しが出現する
$(function() {
    $("#c-heading02__service").each(function() {
      $(this).addClass("slideUp");
    });
    $("#c-heading02__faq").each(function() {
      $(this).addClass("slideUp");
    });
    $("#c-heading02__termsOfUse").each(function() {
      $(this).addClass("slideUp");
    });
    $("#c-heading02__privacyPolicy").each(function() {
      $(this).addClass("slideUp");
    });
    $("#c-heading02__registerInput").each(function() {
      $(this).addClass("slideUp");
    });
    $("#c-heading02__registerConfirm").each(function() {
      $(this).addClass("slideUp");
    });
    $("#c-heading02__registerThanks").each(function() {
      $(this).addClass("slideUp");
    });
    $("#c-heading02__contactInput").each(function() {
      $(this).addClass("slideUp");
    });
    $("#c-heading02__contactConfirm").each(function() {
      $(this).addClass("slideUp");
    });
    $("#c-heading02__contactThanks").each(function() {
      $(this).addClass("slideUp");
    });
});





//ページ内アンカーリンクの設定
$(function(){
  $('a[href^="#"]').on('click', function(){
    const adjust = -20;
    const speed = 200;
    const href= $(this).attr("href");
    const target = $(href == "#" || href == "" ? 'html' : href);
    const position = target.offset().top + adjust;
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });
});

// inputページのチェックボックスをクリックしたらcheckedクラスがついて印が付く
$(function () {
  $('.p-registerInput__checkBox').on('click', function () {
    $(this).toggleClass("checked");
  });
});

